import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/C1about'
import OurHistory from '../components/AboutUs/OurHistory'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
import HowItWork from '../components/AboutUs/HowItWork'
import TeamMembers from '../components/AboutUs/TeamMembers'
import Testimonials from '../components/AboutUs/Testimonials'
import Partner from '../components/AboutUs/C2partner'
 
const AboutUs = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />

            {/*
            <PageBanner
                pageTitle="À propos"
                homePageText="Menu principal"
                homePageUrl="/" 
                activePageText="À propos de CROWLR"
            />
                    <OurHistory />
                    <WhyChooseUs />
                    <HowItWork />
                    <TeamMembers />
                    <Testimonials />
                    */}
            <AboutUsContent />

            <Partner />
            <Footer />
        </Layout>
    );
}

export default AboutUs;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;