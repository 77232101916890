import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import aboutImage from "../../assets/images/about/about-img5.png";
import starIcon from "../../assets/images/star-icon.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const US = () => {
	const { t } = useTranslation();

	return (
		<section className="about-area pt-100px ptb-100">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="about-image">
							<img src={aboutImage} alt="banner" />
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="about-content">
							<div className="content">
								<span className="sub-title">
									<img src={starIcon} alt="banner" />
									{t("home.USi0")}
								</span>
								<h2>{t("home.USh2")}</h2>
								<p>{t("home.USp1")}</p>

								<ul className="features-list">
									<li>
										<h3>2011-2021</h3>
										<p>#Benchmarking</p>
									</li>
									<li>
										<h3>7+</h3>
										<p>#HappyColleagues</p>
									</li>
									<li>
										<h3>100%</h3>
										<p>#International</p>
									</li>
									<li>
										<h3>4M</h3>
										<p>#CostSaving</p>
									</li>
								</ul>

								<Link to="/contact" className="default-btn">
									<i className="flaticon-right"></i>
									{t("NousContacter")}
									<span></span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="circle-shape1">
				<img src={shape1} alt="banner" />
			</div>

			<div className="container">
				<div className="about-inner-area">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-6">
							<div className="about-text">
								<h3>{t("home.USh31")}</h3>
								<p>{t("home.USp2")}</p>

								<ul className="features-list">
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi1")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi2")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi3")}
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6">
							<div className="about-text">
								<h3>{t("home.USh32")}</h3>
								<p>{t("home.USp3")}</p>

								<ul className="features-list">
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi4")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi5")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi6")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi7")}
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
							<div className="about-text">
								<h3>{t("home.USh33")}</h3>
								<p>{t("home.USp4")}</p>

								<ul className="features-list">
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi8")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi9")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi10")}
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.USi11")}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="circle-shape1">
				<img src={shape1} alt="banner" />
			</div>
		</section>
	);
};

export default US;
