import React from 'react'
import ReactWOW from 'react-wow'
import partner1 from '../../assets/images/partner/partner1.png'
import partner2 from '../../assets/images/partner/partner2.png'
import partner3 from '../../assets/images/partner/partner3.png'
import partner4 from '../../assets/images/partner/partner4.png'
import partner5 from '../../assets/images/partner/partner5.png'
import partner6 from '../../assets/images/partner/partner6.png'
import partner7 from '../../assets/images/partner/partner7.png'
import partner8 from '../../assets/images/partner/partner8.png'
import partner9 from '../../assets/images/partner/partner9.png'
import partner10 from '../../assets/images/partner/partner10.png'
import partner11 from '../../assets/images/partner/partner11.png'
import partner12 from '../../assets/images/partner/partner12.png'
import partner13 from '../../assets/images/partner/partner13.png'
import partner14 from '../../assets/images/partner/partner14.png'
import {Link} from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import {useTranslation} from "gatsby-plugin-react-i18next";

const USb = () => {
    const {t} = useTranslation();

    return (
        <div className="partner-area ptb-70 bg-f1f8fb">
            <div className="container">

                <div className="about-content">
                <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    {t('home.USbi')}
                                </span>
                </div>

                    <ReactWOW delay='.1s' animation='fadeInLeft'>
                        <h1>{t('home.USbh1')}</h1>
                    </ReactWOW>

                    <ReactWOW delay='.1s' animation='fadeInLeft'>
                    <p>{t('home.USbp1')}</p>
                        <p>{t('home.USbp2')}</p>
                    </ReactWOW>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner1} alt="about" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner2} alt="about" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner3} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner4} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner5} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner6} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner7} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner8} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner9} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner10} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner11} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner12} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner13} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-8 col-sm-2 col-md-2">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner14} alt="about" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                </div>

                        <p>{t('home.USbp3')}</p>

                <div className="btn-box">
                    <Link
                        to="https://admin.crowlr.com/register"
                        className="default-btn"
                    >
                        <i className="flaticon-tick"></i>
                        {t("Créeruncomptegratuit")}
                        <span></span>
                    </Link>
                </div>


            </div>
        </div>
    )
}

export default USb